import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/elements/title"
import SubTitle from "../components/elements/subtitle"
import Reviews from "../components/Reviews"
import Services from "../components/services"
import FeaturedProjects from "../components/featuredProjects"

const AboutPage = () => (
  <Layout>
    <SEO title="Despre" />
    <div className="section">
      <div className="container">
        <Title title="Oamenii din spatele proiectelor" />
        <SubTitle
          subtitle="Suntem un hub digital ce oferă servicii de strategie, UX design și dezvoltare de aplicații web. Aducem la un loc experiența, seriozitatea și pasiunea pentru design pentru ca produsele 
          digitale pe care le livrăm să fie pe placul oamenilor."
        />
      </div>
    </div>
    <Reviews />
    <Services />
    <FeaturedProjects />
  </Layout>
)

export default AboutPage
